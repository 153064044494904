<template>
  <v-container fluid>
    <div class="app">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <div class="md-card md-theme-default">
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
            >
              <div class="md-card-content">
                <div class="search_customer">
                  <div class="header_title">Venue Users</div>
                  <v-row>
                    <v-col md="2">
                      <div style="width: 120px; float: left" class="per-page">
                        <v-select
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          filled
                          v-model="perPage"
                          label="Per Page"
                          :items="[10, 15, 25, 50]"
                          @change="getUsersList"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col md="2" class="text-right">
                      <v-btn
                        v-if="
                          checkWritePermission($modules.settings.users.slug)
                        "
                        dark
                        color="#00b0af"
                        @click="addUserModal"
                      >
                        <v-icon>mdi-plus</v-icon> User
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <div
                    class="md-content md-table md-theme-default table_borders"
                    table-header-color="orange"
                    value
                  >
                    <div
                      class="md-content md-table-content md-scrollbar md-theme-default"
                    >
                      <table class="listing_table">
                        <thead class="md-card-header">
                          <tr>
                            <th class="five"></th>
                            <th class="md-table-head ten">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Role</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    clearable
                                    small-chips
                                    solo
                                    filled
                                    label="Roles"
                                    v-model="searchParams.role_ids"
                                    item-value="id"
                                    item-text="title"
                                    :items="roles"
                                    :loading="isLoading"
                                    multiple
                                    @change="getUsersList"
                                  >
                                    <template
                                      v-if="searchParams.role_ids.length > 1"
                                      v-slot:selection="{ index }"
                                    >
                                      <span v-if="index == 0">
                                        {{
                                          roles.find(
                                            (el) =>
                                              el.id == searchParams.role_ids[0]
                                          ).title
                                        }}...
                                      </span>
                                    </template></v-autocomplete
                                  >
                                </div>
                              </div>
                            </th>

                            <th class="md-table-head ten">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Username</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    clearable
                                    solo
                                    filled
                                    label="Username"
                                    v-model="searchParams.username"
                                    item-value="username"
                                    item-text="username"
                                    :items="usernames"
                                    :loading="isLoading"
                                    @update:search-input="
                                      getFilterData('username', $event)
                                    "
                                    @change="getUsersList"
                                  >
                                  </v-autocomplete>
                                </div>
                              </div>
                            </th>

                            <th class="md-table-head ten">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Name</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    clearable
                                    solo
                                    filled
                                    outlined
                                    background-color="#fff"
                                    label="Name"
                                    v-model="searchParams.name"
                                    item-value="name"
                                    item-text="name"
                                    :items="names"
                                    :loading="isLoading"
                                    @update:search-input="
                                      getFilterData('name', $event)
                                    "
                                    @change="getUsersList"
                                  >
                                  </v-autocomplete>
                                </div>
                              </div>
                            </th>

                            <th class="md-table-head ten">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Email</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    clearable
                                    solo
                                    filled
                                    outlined
                                    background-color="#fff"
                                    label="Email"
                                    v-model="searchParams.email"
                                    item-value="email"
                                    item-text="email"
                                    :items="emails"
                                    :loading="isLoading"
                                    @update:search-input="
                                      getFilterData('email', $event)
                                    "
                                    @change="getUsersList"
                                  >
                                  </v-autocomplete>
                                </div>
                              </div>
                            </th>

                            <th class="md-table-head ten">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Mobile</div>
                                <div class="search_column">
                                  <v-autocomplete
                                    clearable
                                    solo
                                    filled
                                    outlined
                                    background-color="#fff"
                                    label="Mobile"
                                    v-model="searchParams.mobile"
                                    item-value="mobile"
                                    item-text="mobile"
                                    :items="mobiles"
                                    :loading="isLoading"
                                    @update:search-input="
                                      getFilterData('mobile', $event)
                                    "
                                    @change="getUsersList"
                                  ></v-autocomplete>
                                </div>
                              </div>
                            </th>

                            <th
                              class="five"
                              v-if="
                                checkWritePermission(
                                  $modules.settings.users.slug
                                )
                              "
                            ></th>
                            <th
                              class="five"
                              v-if="
                                checkDeletePermission(
                                  $modules.settings.users.slug
                                )
                              "
                            ></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            class="md-table-row"
                            v-for="(user, i) in userList"
                            :key="i"
                          >
                            <!---->
                            <td class="md-table-cell">
                              <div class="md-table-cell-container">
                                <v-avatar size="36">
                                  <view-image
                                    :image="user.profile_image"
                                    defaultImage="user"
                                    :contain="false"
                                  ></view-image>
                                </v-avatar>
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container"
                                style="overflow-wrap: break-word"
                              >
                                {{
                                  user.roles.map((role) => role.title).join(",")
                                }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container"
                                style="overflow-wrap: break-word"
                              >
                                {{ user.username }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container"
                                style="overflow-wrap: break-word"
                              >
                                {{ user.first_name }} {{ user.last_name || "" }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-truncate"
                                style="
                                  max-width: 220px;
                                  overflow-wrap: break-word;
                                "
                              >
                                {{ user.email }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container">
                                {{ user.mobile }}
                              </div>
                            </td>

                            <td
                              class="md-table-cell"
                              v-if="
                                checkWritePermission(
                                  $modules.settings.users.slug
                                )
                              "
                            >
                              <div class="md-table-cell-container">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="showUserModal(user.id)"
                                      fab
                                      dark
                                      x-small
                                      color="#00b0af"
                                      value="nearby"
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </template>
                                  Edit
                                </v-tooltip>
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="
                                checkDeletePermission(
                                  $modules.settings.users.slug
                                )
                              "
                            >
                              <div
                                class="md-table-cell-container"
                                v-if="
                                  user.id != $store.getters.userInfo.user_id
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      v-if="
                                        user.id !=
                                          $store.getters.getLoggedInUserID &&
                                        user.is_admin == 0
                                      "
                                      fab
                                      dark
                                      x-small
                                      color="red"
                                      value="nearby"
                                      @click="
                                        deleteConfirm({ index: i, id: user.id })
                                      "
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  Delete
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <v-pagination
                    v-model="page"
                    :length="totalPages"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="addModal" width="50%" scrollable @input="closeAddUser">
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-text class="pt-4">
              <div class="row pt-1 border-bottom mb-3">
                <div class="col-md-12">
                  <div class="d-flex justify-space-between align-center mt-2">
                    <SvgIcon
                      class="text-2xl font-semibold"
                      :text="`${editFlag ? 'Edit User' : 'Add User'}`"
                      style="color: black"
                    >
                    </SvgIcon>
                    <v-btn fab x-small class="shadow-0" @click="closeAddUser">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-row no-gutters justify="center">
                <v-col cols="3" style="width: 100%; height: 220px">
                  <image-uploader
                    @upload="
                      (data) => {
                        userData.image = data;
                      }
                    "
                    @remove="
                      () => {
                        userData.image = null;
                      }
                    "
                    ref="image_upload"
                    :image_path="userData.image_path"
                    text="User Profile Image"
                  ></image-uploader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <label for="">Username*</label>
                  <v-text-field
                    v-model="userData.username"
                    label=""
                    required
                    outlined
                    autocomplete="off"
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Username is required']"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" v-if="!editFlag">
                  <label for="">Password*</label>
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
                    label=""
                    outlined
                    autocomplete="new-password"
                    background-color="#fff"
                    v-model="userData.password"
                    @click:append="hidePassword = !hidePassword"
                    required
                    :rules="[(v) => !!v || 'Password is required']"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <label for="">First name*</label>
                  <v-text-field
                    v-model="userData.first_name"
                    label=""
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'First name is required']"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="">Last name*</label>
                  <v-text-field
                    v-model="userData.last_name"
                    label=""
                    outlined
                    background-color="#fff"
                    required
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <label for="">Mobile</label>
                  <mobile-number-field
                    v-model="userData.mobile"
                    :dense="true"
                    :outlined="true"
                    :variant="1"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <label for="">Email*</label>
                  <v-text-field
                    v-model="userData.email"
                    label=""
                    outlined
                    background-color="#fff"
                    required
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="">Roles*</label>
                  <v-select
                    v-model="userData.role"
                    :items="roles"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    item-text="title"
                    label=""
                    multiple
                    :rules="[(v) => v.length > 0 || 'Role is required']"
                    @change="getRolePermission"
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    checkReadPermission($modules.memberships.dashboard.slug)
                  "
                >
                  <label for="">Partner Categories</label>
                  <v-select
                    v-model="carousel_id"
                    label=""
                    :items="carousels"
                    item-text="title"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    @change="getCarouselPartners"
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" v-if="partners.length > 0">
                  <label for="">Venue Partners</label>
                  <v-select
                    v-model="partner_id"
                    label=""
                    :items="partners"
                    item-text="title"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="$store.getters.userVenues.length > 1"
                >
                  <label for="">Venues*</label>
                  <v-select
                    v-model="userData.venue_ids"
                    :items="venues"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    item-text="name"
                    label=""
                    multiple
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" v-if="venueServices.length > 0">
                  <label for="">Services *</label>
                  <v-select
                    v-model="userData.venue_services"
                    :items="venueServices"
                    item-value="id"
                    outlined
                    :readonly="isSuperAdmin()"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    item-text="name"
                    label=""
                    multiple
                    dense
                    hide-details="auto"
                    class="q-autocomplete shadow-0"
                  ></v-select>
                </v-col>
              </v-row>
              <v-expansion-panels
                v-if="
                  typeof userPermissions !== 'undefined' &&
                  userPermissions.length > 0
                "
                focusable
                flat
                class="mt-4"
              >
                <template
                  v-for="(userRolePermission, rIndex) in userPermissions"
                >
                  <v-expansion-panel
                    :key="rIndex"
                    v-if="userRolePermission != null"
                  >
                    <v-expansion-panel-header color="#A6D7D7">
                      <span>{{ userRolePermission.title }} Permission</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="transparent" class="pt-4">
                      <v-row>
                        <v-col>
                          <v-expansion-panels>
                            <template
                              v-for="(
                                modules, mIndex
                              ) in userRolePermission.permissions"
                            >
                              <v-expansion-panel :key="`${rIndex}_${mIndex}`">
                                <v-expansion-panel-header color="#DCEFEF">{{
                                  modules.module_name
                                }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="pt-3">
                                  <table class="permission_table">
                                    <thead>
                                      <tr>
                                        <th>Module</th>
                                        <th>Read</th>
                                        <th>Write</th>
                                        <th>Delete</th>
                                        <th>Export</th>
                                        <th>Backfill</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="(
                                          permission, index
                                        ) in modules.sub_modules"
                                        :key="`${rIndex}_${mIndex}_${index}`"
                                      >
                                        <td>
                                          {{ permission.sub_module_name }}
                                        </td>
                                        <td>
                                          <v-btn
                                            icon
                                            :color="
                                              permission.allow_read == 1
                                                ? 'success'
                                                : 'error'
                                            "
                                            @click="
                                              changeModulePermission(
                                                'read',
                                                rIndex,
                                                mIndex,
                                                index
                                              )
                                            "
                                          >
                                            <v-icon>{{
                                              permission.allow_read == 1
                                                ? "mdi-check"
                                                : "mdi-close"
                                            }}</v-icon>
                                          </v-btn>
                                        </td>
                                        <td>
                                          <v-btn
                                            icon
                                            :color="
                                              permission.allow_write == 1
                                                ? 'success'
                                                : 'error'
                                            "
                                            @click="
                                              changeModulePermission(
                                                'add',
                                                rIndex,
                                                mIndex,
                                                index
                                              )
                                            "
                                          >
                                            <v-icon>{{
                                              permission.allow_write == 1
                                                ? "mdi-check"
                                                : "mdi-close"
                                            }}</v-icon>
                                          </v-btn>
                                        </td>
                                        <td>
                                          <v-btn
                                            icon
                                            :color="
                                              permission.allow_delete == 1
                                                ? 'success'
                                                : 'error'
                                            "
                                            @click="
                                              changeModulePermission(
                                                'delete',
                                                rIndex,
                                                mIndex,
                                                index
                                              )
                                            "
                                          >
                                            <v-icon>{{
                                              permission.allow_delete == 1
                                                ? "mdi-check"
                                                : "mdi-close"
                                            }}</v-icon>
                                          </v-btn>
                                        </td>
                                        <td>
                                          <v-btn
                                            icon
                                            :color="
                                              permission.allow_export == 1
                                                ? 'success'
                                                : 'error'
                                            "
                                            @click="
                                              changeModulePermission(
                                                'export',
                                                rIndex,
                                                mIndex,
                                                index
                                              )
                                            "
                                          >
                                            <v-icon>{{
                                              permission.allow_export == 1
                                                ? "mdi-check"
                                                : "mdi-close"
                                            }}</v-icon>
                                          </v-btn>
                                        </td>
                                        <td>
                                          <v-btn
                                            icon
                                            :color="
                                              permission.allow_backfill == 1
                                                ? 'success'
                                                : 'error'
                                            "
                                            @click="
                                              changeModulePermission(
                                                'backfill',
                                                rIndex,
                                                mIndex,
                                                index
                                              )
                                            "
                                          >
                                            <v-icon>{{
                                              permission.allow_backfill == 1
                                                ? "mdi-check"
                                                : "mdi-close"
                                            }}</v-icon>
                                          </v-btn>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </template>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="editFlag"
                class="ma-2 yellow-color"
                text
                @click="openChangePassword"
                >Change Password</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn class="ma-2" text @click="closeAddUser">Close</v-btn>

              <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="addUpdateUser"
                >{{ editFlag ? "Update User" : "Add User" }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog
        v-model="passwordDialog"
        max-width="400"
        scrollable
        @input="closeChangePassword"
      >
        <v-form ref="passowrd_form" v-model="valid">
          <v-card>
            <v-card-title class="headline">Change User Password</v-card-title>
            <v-card-text class="form_bg pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    :type="password.new_hide ? 'password' : 'text'"
                    :append-icon="password.new_hide ? 'mdi-eye-off' : 'mdi-eye'"
                    name="Password"
                    label="New Password"
                    outlined
                    background-color="#fff"
                    v-model="password.password"
                    @click:append="password.new_hide = !password.new_hide"
                    required
                    :rules="[
                      (v) => !!v || 'Password is required',
                      (v) =>
                        (v && v.length >= 6) ||
                        'Password should be atleast 6 characters long',
                    ]"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    :type="password.confirm_hide ? 'password' : 'text'"
                    :append-icon="
                      password.confirm_hide ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    name="Password"
                    label="Confirm Password"
                    outlined
                    background-color="#fff"
                    v-model="password.password_confirmation"
                    @click:append="
                      password.confirm_hide = !password.confirm_hide
                    "
                    required
                    :rules="confirmRules()"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2 white--text blue-color"
                text
                @click="closeChangePassword"
                >Close</v-btn
              >
              <v-btn
                class="ma-2 white--text teal-color"
                text
                @click="changePassword"
                >Change Password</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
      ></confirm-model>
    </div>
  </v-container>
</template>

<script>
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
export default {
  components: {
    ImageUploader,
    MobileNumberField,
    SvgIcon,
    ConfirmModel,
  },
  data() {
    return {
      searchParams: {
        role_ids: [],
        username: null,
        name: null,
        mobile: null,
        email: null,
      },
      carousels: [],
      partners: [],
      carousel_id: null,
      partner_id: null,
      items: [],
      userData: { mobile: null },
      userList: [],
      editFlag: false,
      addModal: false,
      rolePermissions: [],
      userPermissions: [],
      hidePassword: true,
      roles: [],
      page: 1,
      perPage: 6,
      valid: true,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      password: { current_hide: true, new_hide: true, confirm_hide: true },
      passwordDialog: false,
      mobiles: [],
      emails: [],
      names: [],
      usernames: [],
      totalPages: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getUsersList();
    this.getVenueRolesList();

    this.checkPermission = this.checkReadPermission(
      this.$modules.memberships.dashboard.slug
    );
    if (this.checkPermission == true) this.getCarousel();

    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  computed: {
    venueServices() {
      // return this.$store.getters.getVenueServices.data.filter(
      //   (service) => service.name != "POS"
      // );
      return this.$store.getters.getVenueServices.data;
    },
    venues() {
      let currentVenueCode = this.$store.getters.venueInfo.code;
      return this.$store.getters.userVenues.filter(
        (item) => item.code != currentVenueCode
      );
    },
    userProfileModule() {
      return process.env.VUE_APP_USER_PROFILE_MODULE;
    },
  },
  watch: {
    page() {
      this.getUsersList();
    },
  },
  methods: {
    isSuperAdmin() {
      if (this.userData.role) {
        return this.userData.role.includes(1);
      } else {
        return true;
      }
    },
    getFilterData(type, val) {
      if (
        val == "All" ||
        val == null ||
        this.searchParams[type] == val ||
        val == ""
      )
        return;
      this.isLoading = true;
      this.$http
        .get(`venues/users/filters?field=${type}&search=${val}`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift({ name: "All" });
            } else if (type == "mobile") {
              this.mobiles = response.data.data;
              this.mobiles.unshift({ mobile: "All" });
            } else if (type == "username") {
              this.usernames = response.data.data;
              this.usernames.unshift({ username: "All" });
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift({ email: "All" });
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getUsersList() {
      this.showLoader("Loading..");
      let url = `&per_page=${this.perPage}`;
      for (const [key, value] of Object.entries(this.searchParams)) {
        if (value && value != "All") {
          if (Array.isArray(value) && value.length > 0) {
            url += `&${value
              .map((role, index) => `${key}[${index}]=${role}`)
              .join("&")}`;
          } else if (!Array.isArray(value)) {
            url += `&${key}=${value}`;
          }
        }
      }
      this.$http
        .get(`venues/users?page=${this.page}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.userList = response.data.data;
            this.totalPages = response.data.total_pages;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addUserModal() {
      this.userData = {
        username: null,
        password: null,
        name: null,
        email: null,
        mobile: null,
        venue_ids: [],
        venue_services: [],
        role: [],
      };
      this.userPermissions = [];
      this.editFlag = false;
      this.addModal = true;
      this.carousel_id = null;
      this.partner_id = null;
      setTimeout(() => {
        this.$refs["image_upload"].cancel();
        this.$refs.form.resetValidation();
      });
    },
    deleteConfirm(data) {
      this.confirmModel = {
        id: data.id,
        title: "Do you want to delete this user?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_post",
      };
    },
    confirmActions(data) {
      if (data.type == "delete_post") {
        this.deleteUser(data.id);
      }
      this.confirmModel.id = null;
    },
    addUpdateUser() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      event.preventDefault();
      this.showLoader();
      let formData = new FormData();
      this.userPermissions.forEach((roles, ri) => {
        formData.set(`roles[${ri}][id]`, roles.role_id);
        let pi = 0;
        roles.permissions.forEach((modules) => {
          modules.sub_modules.forEach((permission) => {
            formData.set(
              `roles[${ri}][permissions][${pi}][venue_subscription_id]`,
              permission.venue_subscription_id
            );
            formData.set(
              `roles[${ri}][permissions][${pi}][allow_delete]`,
              permission.allow_delete
            );
            formData.set(
              `roles[${ri}][permissions][${pi}][allow_export]`,
              permission.allow_export
            );
            formData.set(
              `roles[${ri}][permissions][${pi}][allow_read]`,
              permission.allow_read
            );
            formData.set(
              `roles[${ri}][permissions][${pi}][allow_write]`,
              permission.allow_write
            );
            formData.set(
              `roles[${ri}][permissions][${pi}][allow_backfill]`,
              permission.allow_backfill
            );
            pi += 1;
          });
        });
      });
      if (this.userData.venue_ids.length > 0) {
        this.userData.venue_ids.forEach((venueId, index) => {
          formData.set(`venue_ids[${index}]`, venueId);
        });
      }
      if (this.userData.venue_services.length > 0) {
        this.userData.venue_services.forEach((venueId, index) => {
          formData.set(`venue_services[${index}]`, venueId);
        });
      }

      if (this.carousel_id != null && this.partner_id == null) {
        this.hideLoader();
        this.showError("Please Select Partner");
        return false;
      }
      if (this.carousel_id != null) {
        formData.set("carousel_id", this.carousel_id);
        formData.set("partner_id", this.partner_id);
        if (this.partner_id.length > 0) {
          this.partner_id.forEach((partnerId, index) => {
            formData.set(`partner_id[${index}]`, partnerId);
          });
        }
      }

      formData.set("username", this.userData.username);
      if (this.userData.id == null) {
        formData.set("password", this.userData.password);
      }
      formData.set("first_name", this.userData.first_name);
      formData.set("last_name", this.userData.last_name);
      formData.set("email", this.userData.email);
      formData.set("mobile", this.userData.mobile);
      if (this.userData.image != null) {
        formData.set("image", this.userData.image);
      }
      this.$http
        .post(
          `venues/users${
            this.userData.id != null ? "/" + this.userData.id : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.addModal = false;
            const data = response.data.data;
            this.showSuccess(response.data.message);
            this.hideLoader();
            this.getUsersList();
            if (this.userData.id == this.$store.getters.userInfo.user_id) {
              const user = {
                username: data.username,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                mobile: data.mobile,
                image_path: data.profile_image,
              };
              this.$store.commit("update_user_info", user);
              this.$store.dispatch("loadPermissions").then((response) => {
                if (response) this.showSuccess("Permission updated");
              });
            }
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    showUserModal(id) {
      this.$http
        .get("venues/users/" + id)
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.data;
            this.userData.first_name = data.first_name ? data.first_name : null;
            this.userData.last_name = data.last_name ? data.last_name : null;
            this.userData.id = data.id ? data.id : null;
            this.userData.username = data.username ? data.username : null;
            this.userData.mobile = data.mobile ? data.mobile : null;
            this.userData.email = data.email ? data.email : null;

            this.userData.role = data.roles.map((role) => role.role_id);
            if (this.userData.role.length == 0) {
              this.showInfo(
                "No roles assigned. Please assign role to provide access for user"
              );
            }
            this.userData.image_path =
              data.profile_image != "undefined" && data.profile_image != null
                ? data.profile_image
                : null;
            this.userData.venue_ids = data.venues.map((item) => item.venue_id);
            this.userData.venue_services = data.venue_services.map(
              (item) => item.id
            );
            this.userPermissions = data.roles;
            this.addModal = true;
            this.editFlag = true;
            if (data.outlets.length > 0) {
              this.carousel_id = data.outlets[0].carousel_id;
              this.getCarouselPartners();
              this.partner_id = data.outlets[0].partner_id;
              this.partner_id = data.outlets.map((item) => item.partner_id);
            }
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteUser(id) {
      this.$http
        .delete("venues/users/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess("User Deleted Successfully.");
            this.getUsersList();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    closeAddUser() {
      this.addModal = false;
      this.$refs.form.resetValidation();
    },
    getVenueRolesList() {
      this.$http
        .get("venues/roles?has_permissions=true")
        .then((response) => {
          if (response.status == 200) this.roles = response.data.data;
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getRolePermission() {
      if (this.userData.role.length == 0) {
        return;
      }
      this.$http
        .get(
          `venues/roles/multiple?${this.userData.role
            .map((role, index) => `role_ids[${index}]=${role}`)
            .join("&")}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.userPermissions = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeModulePermission(type, roleIndex, moduleIndex, subIndex) {
      let currentPermission =
        this.userPermissions[roleIndex].permissions[moduleIndex].sub_modules[
          subIndex
        ];
      if (type == "read") {
        if (currentPermission.module_name == this.userProfileModule) {
          this.showError("Error! User basic read permission cannot be changed");
          return;
        }
        if (
          this.$store.getters.userInfo.user_id == this.userData.id &&
          currentPermission.slug == this.$modules.settings.users.slug
        ) {
          this.showError("Access for current user cannot be disabled");
          return;
        }
        this.userPermissions[roleIndex].permissions[moduleIndex].sub_modules[
          subIndex
        ].allow_read = currentPermission.allow_read == 1 ? 0 : 1;
      } else if (type == "add") {
        this.userPermissions[roleIndex].permissions[moduleIndex].sub_modules[
          subIndex
        ].allow_write = currentPermission.allow_write == 1 ? 0 : 1;
      } else if (type == "delete") {
        this.userPermissions[roleIndex].permissions[moduleIndex].sub_modules[
          subIndex
        ].allow_delete = currentPermission.allow_delete == 1 ? 0 : 1;
      } else if (type == "export") {
        this.userPermissions[roleIndex].permissions[moduleIndex].sub_modules[
          subIndex
        ].allow_export = currentPermission.allow_export == 1 ? 0 : 1;
      } else if (type == "backfill") {
        this.userPermissions[roleIndex].permissions[moduleIndex].sub_modules[
          subIndex
        ].allow_backfill = currentPermission.allow_backfill == 1 ? 0 : 1;
      }
      this.$forceUpdate();
    },
    closeChangePassword() {
      this.passwordDialog = false;
      this.addModal = true;
      this.password = {
        current_hide: true,
        new_hide: true,
        confirm_hide: true,
      };
    },
    openChangePassword() {
      this.passwordDialog = true;
      this.addModal = false;
      this.password.user_id = this.userData.id;
    },
    changePassword() {
      if (!this.$refs.passowrd_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      this.$http
        .put(`venues/users/${this.password.user_id}/password`, this.password)
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.showSuccess(response.data.message);
            this.closeChangePassword();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    confirmRules() {
      let rules = [];
      if (this.password.password_confirmation == null) {
        rules.push("Confirm password is required");
      }
      if (this.password.password_confirmation != this.password.password) {
        rules.push("Confirm password does not match");
      }
      return rules;
    },
    getCarousel() {
      this.$http
        .get("venues/memberships/carousels")
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.carousels = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCarouselPartners() {
      this.partner_id = null;
      this.$http
        .get(
          "venues/memberships/carousels-partners?carousel_id=" +
            this.carousel_id
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            // console.log(JSON.stringify(response.data.data));
            this.partners = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggleSelectPartner() {
      this.$nextTick(() => {
        if (this.partners.length == this.partner_id.length) {
          this.partner_id = [];
        } else {
          this.partner_id = this.partners.map((item) => item.id);
        }
      });
    },
    getServiceIcon() {
      if (this.partner_id.length == 0) return "mdi-checkbox-blank-outline";
      if (this.partner_id.length == this.partners.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
  },
};
</script>

<style scoped>
.user-wrapper {
  margin: 0px 10px 20px 10px;
}
.permission_table {
  width: 100%;
  text-align: center !important;
}
.permission_table th {
  text-align: center !important;
}
.permission_table td,
.permission_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.permission_table tr {
  background-color: #f2f2f2;
}
.permission_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}

.permission_table tr:hover {
  background-color: #ddd;
}
.permission_table .header {
  background-color: rgb(209, 209, 209);
}

.permission_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
</style>
